.choice-site-footer {
  color: $footer-text-color;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  padding: 32px 0 33px;

  @include breakpoint($sm) {
    padding-bottom: 42px;
  }

  @include breakpoint($xg) {
    padding-bottom: 50px;
  }

  p {
    margin-bottom: 30px;
  }

  nav {
    margin: 16px 17px;
    text-align: left !important;

    @include breakpoint($sm) {
      margin: 16px 41px;
    }

    @include breakpoint($md) {
      text-align: right !important;
    }

    @include breakpoint($xg) {
      margin: 16px 10px;
    }

    ul {
      li {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        padding-right: 10px;
        position: relative;

        &:last-child {
          padding-right: 0;
        }

        &:after {
          content: "|";
          display: inline-block;
          margin-bottom: 6px;
          padding-left: 14px;
        }
      }
    }
  }

  .logo {
    height: 45px !important;
    margin: 0 13px !important;

    @include breakpoint($sm) {
      margin: 0 38px !important;
    }

    @include breakpoint($xg) {
      margin: 0 8px !important;
    }
  }

  .social {
    float: none;
    margin: 40px 17px 38px;

    @include breakpoint($sm) {
      margin: 40px 41px !important;
    }

    @include breakpoint($xg) {
      margin: 40px 10px !important;
    }

    a {
      font-size: 22px;
    }
  }

  .legal {
    margin: 0 17px;

    @include breakpoint($sm) {
      margin: 0 41px;
    }

    @include breakpoint($xg) {
      margin: 0 10px;
    }
  }
}
